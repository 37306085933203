import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
} from "@material-ui/core";

import imageUrlBuilder from "@sanity/image-url";
import sanity from "~/src/api/sanity";

import {
  Link,
  Strong,
} from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  link: {
    "&:hover $title": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
  image: {
    width: "100%",
    borderRadius: theme.shape.borderRadiusLarge,
  },
  title: { },
}));

const builder = imageUrlBuilder(sanity);

function urlFor(source) {
  return builder.image(source);
}

function GridItemContent(props) {
  const {
    url, image, title, subtitle,
  } = props;
  const classes = useStyles();

  const markup = (
    <>
      <img src={urlFor(image).width(300).height(200)} alt={title} className={classes.image} />
      <Typography className={classes.title}>
        <Strong>{title}</Strong>
      </Typography>
      {subtitle && <Typography color="textSecondary">{subtitle}</Typography>}
    </>
  );

  return url ? (
    <Link href={url} unstyled>
      <div className={classes.link}>
        {markup}
      </div>
    </Link>
  ) : markup;
}

export default GridItemContent;
