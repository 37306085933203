import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GridItem } from "~/src/components/global";
import { TeacherHeroImage } from "~/src/components/teachers";
import * as ROUTES from "~/src/constants/routes";
import MONTHS_OF_THE_YEAR from "~/src/constants/months";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 40,
    [theme.breakpoints.up("md")]: {
      width: 142,
      margin: theme.spacing(-1),
      marginRight: 0,
    },
  },
}));

function TeacherGridItem({ post }) {
  const classes = useStyles();

  if (!post) return null;

  const date = new Date(post.publishedAt);
  const dateMarkup = date ? `${date.getDate()} ${MONTHS_OF_THE_YEAR[date.getMonth()].label} ${date.getFullYear()}` : null;

  return (
    <GridItem
      image={(
        <div className={classes.image}>
        <TeacherHeroImage
          alt={post.title}
          source={`${post.mainImage}?h=256`}
        />
        </div>
      )}
      title={post.title}
      tertiary={dateMarkup}
      description={post.excerpt}
      href={ROUTES.BLOG_POST}
      as={`/blog/${post.slug.current}`}
    />
  );
}

export default TeacherGridItem;
