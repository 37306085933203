import React from "react";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";

import MailchimpSubscribe from "react-mailchimp-subscribe";

import {
  Banner,
  Button,
  Spacing,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email address")
    .required("Email address is required"),
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
  },
  textfield: {
    flexGrow: 1,
    marginRight: theme.spacing(),
  },
}));

export default function MailchimpForm() {
  const classes = useStyles();

  return (
    <MailchimpSubscribe
      url="https://bolstr.us4.list-manage.com/subscribe/post?u=2f2004af94149b276447fa62d&amp;id=8a3d619473"
      render={({ subscribe, status, message }) => (
        <>
          {status !== "success" && (
<Formik
  initialValues={{
    email: "",
  }}
  validationSchema={validationSchema}
  onSubmit={(values) => {
    subscribe({
      EMAIL: values.email,
    });
  }}
>
            {({ isSubmitting }) => (
              <Form>
                <div className={classes.form}>
                  <div className={classes.textfield}>
                    <Field
                      placeholder="Type your email address..."
                      name="email"
                      type="email"
                      component={TextField}
                    />
                  </div>
                  <Button
                    color="primary"
                    loading={isSubmitting}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          )}
          {status === "sending" && (
            <>
              <Spacing tight />
              <Banner status="info">Sending...</Banner>
            </>
          )}
          {status === "error" && (
            <>
              <Spacing tight />
              <Banner status="danger">{message}</Banner>
            </>
          )}
          {status === "success" && <Banner status="success">Subscribed!</Banner>}
        </>
      )}
    />
  );
}
