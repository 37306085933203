import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";

import {
  Link,
  Button,
  Spacing,
} from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  image: {
    display: "block",
    width: "100%",
    height: 200,
    objectFit: "cover",
    objectPosition: "top center",
    borderRadius: theme.shape.borderRadiusLarge,
    [theme.breakpoints.up("sm")]: {
      minHeight: 320,
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: 360,
    },
  },
  imageGridItem: {
    [theme.breakpoints.up("sm")]: {
      order: 2,
    },
  },
}));

function Hero(props) {
  const {
    children, pretitle, title, subtitle, button, image, imageAlt, reverse, alignItems,
  } = props;
  const classes = useStyles();

  const buttonMarkup = button ? (
    <Hidden xsDown implementation="css">
      <Spacing tight />
      <Link unstyled href={button.href}>
        <Button color="primary" size="large">{button.label}</Button>
      </Link>
    </Hidden>
  ) : null;

  return (
    <>
      <Grid container spacing={4} alignItems={alignItems}>
        {image && (
          <Grid item xs={12} sm={6} className={reverse ? undefined : classes.imageGridItem}>
            <img src={image} alt={imageAlt} className={classes.image} />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          {pretitle}
          {title && (
            <Typography variant="h1" gutterBottom>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant="h3" color="textSecondary">
              {subtitle}
            </Typography>
          )}
          {children}
          {buttonMarkup}
        </Grid>
      </Grid>
    </>
  );
}

export default Hero;
