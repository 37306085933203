import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  image: {
    display: "block",
    borderRadius: theme.shape.borderRadius,
  },
}));

function Image(props) {
  const { src, alt } = props;
  const classes = useStyles();

  return (
      <img
        className={classes.image}
        tabIndex="-1"
        src={src}
        alt={alt}
      />
  );
}

export default Image;
